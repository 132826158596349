import React from "react";

import portrait1 from "../assets/images/portrait-individual-2.jpg";
import portrait2 from "../assets/images/portrait-individual-1.jpg";
import portrait3 from "../assets/images/portrait-individual-3.jpg";

import storyImg1 from "../assets/images/nat-8.jpg";
import storyImg2 from "../assets/images/nat-9.jpg";

import videoMp4 from "../assets/images/video.mp4";
import videoWebm from "../assets/images/video.webm";

import "../assets/css/main.css";

import gal1 from "../assets/images/gallery/Portrait-Group1.jpg";
import gal2 from "../assets/images/gallery/Santa-Portraits-1.jpg";
import gal3 from "../assets/images/gallery/Portrait-Group2.jpg";
import gal4 from "../assets/images/gallery/Portrait-1.jpg";
import gal5 from "../assets/images/gallery/Event-Photography-1.jpg";
import gal6 from "../assets/images/gallery/Event-Photography-2.jpg";
import gal7 from "../assets/images/gallery/Event-Photography-3.jpg";
import gal8 from "../assets/images/gallery/Portrait-2.jpg";

import gal10 from "../assets/images/gallery/Event-Photography-4.jpg";
import gal11 from "../assets/images/gallery/Portrait-3.jpg";
import gal12 from "../assets/images/gallery/Santa-Portraits-2.jpg";
import gal13 from "../assets/images/gallery/Portrait-4.jpg";
import gal14 from "../assets/images/gallery/Photograph-gallery (14).jpg";

import gal15 from "../assets/images/gallery/Portrait-5.jpg";
import gal16 from "../assets/images/gallery/Portrait-6.jpg";
import gal17 from "../assets/images/gallery/Portrait-7.jpg";
import gal18 from "../assets/images/gallery/Portrait-8.jpg";

import story1 from "../assets/images/nat-8.jpg";
import backgrondVideo1 from "../assets/images/video.mp4";
import backgrondVideo2 from "../assets/images/video.webm";

import Footer from "../Components/Layout/Footer";

import Header from "../Components/Layout/Header";

function Home() {
  return (
    <div>
      
      <Header />                           
      <main title="Matzke Photography">
        <section className="section-tours" id="section-tours">
          <div className="u-center-text u-margin-bottom-big">
            <h2 className="heading-secondary">Most popular services</h2>
          </div>

          <div className="row">
            <div className="col-1-of-3">
              <div className="card">
                <div className="card__side card__side--front">
                  <div className="card__picture card__picture--1">&nbsp;</div>
                  <h4 className="card__heading">
                    <span className="card__heading-span card__heading-span--1">
                      Portraits
                    </span>
                  </h4>
                  <div className="card__details">
                    <ul>
                      <li>On location</li>
                      <li>Personal Consultation</li>
                      <li>Customized Session</li>
                      <li>Digital or Prints</li>
                    </ul>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-1">
                  <div className="card__cta">
                    <div className="card__price-box">
                      <p className="card__price-only">Starting at</p>
                      <p className="card__price-value">$300</p>
                    </div>
                    <a href="#popup" className="btn btn--white">
                      Book now!
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-1-of-3">
              <div className="card">
                <div className="card__side card__side--front">
                  <div className="card__picture card__picture--2">&nbsp;</div>
                  <h4 className="card__heading">
                    <span className="card__heading-span card__heading-span--2">
                      Headshots
                    </span>
                  </h4>
                  <div className="card__details">
                    <ul>
                      <li>On location</li>
                      <li>Personal Consultation</li>
                      <li>High Resolution Image</li>
                      <li>Online delivery</li>
                    </ul>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-2">
                  <div className="card__cta">
                    <div className="card__price-box">
                      <p className="card__price-only">Starting at</p>
                      <p className="card__price-value">$150</p>
                    </div>
                    <a href="#popup" className="btn btn--white">
                      Book now!
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-1-of-3">
              <div className="card">
                <div className="card__side card__side--front">
                  <div className="card__picture card__picture--3">&nbsp;</div>
                  <h4 className="card__heading">
                    <span className="card__heading-span card__heading-span--3">
                      Events
                    </span>
                  </h4>
                  <div className="card__details">
                    <ul>
                      <li>Personal Consultation</li>
                      <li>High Resolution Image</li>
                      <li>Online delivery</li>
                    </ul>
                  </div>
                </div>
                <div className="card__side card__side--back card__side--back-3">
                  <div className="card__cta">
                    <div className="card__price-box">
                      <p className="card__price-only">Starting at</p>
                      <p className="card__price-value">$150</p>
                    </div>
                    <a href="#popup" className="btn btn--white">
                      Book now!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section-tours-quote u-center-text u-margin-bottom-small">
            <a href="#popup" className="btn btn--green">
              Request a Quote
            </a>
          </div>
        </section>

        <section className="section-features">
          <div className="row">
            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-world"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  How to Book a Session?
                </h3>
                <p className="feature-box__text">
                  Just fill out our booking form and we will call to confirm
                  availability and discuss your needs to ensure they are met.
                </p>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-compass"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  What services do you offer?
                </h3>
                <div className="feature-box__text">
                  <p>Headshots</p>
                  <p>Family Portraits</p>
                  <p>Corporate Events</p>
                  <p>Creative Projects and Collaborations</p>
                </div>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-map"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  How do I prepare for a session?
                </h3>
                <p className="feature-box__text">
                  We'll provide you with a detailed preparation guide upon
                  booking. It includes tips on clothing choices and any specific
                  details to ensure a smooth and enjoyable session.
                </p>
              </div>
            </div>

            <div className="col-1-of-4">
              <div className="feature-box">
                <i className="feature-box__icon icon-basic-heart"></i>
                <h3 className="heading-tertiary u-margin-bottom-small">
                  What happens in case of bad weather?
                </h3>
                <p className="feature-box__text">
                  In the event of inclement weather, we can reschedule to ensure
                  the best possible conditions. Your comfort and the quality of
                  the images are my top priorities.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section-about">
          <div className="u-center-text u-margin-bottom-big">
            <h2 className="heading-secondary">When moments are important</h2>
          </div>

          <div className="row">
            <div className="col-1-of-2">
              <h3 className="heading-tertiary u-margin-bottom-small">
                Let's Create Together
              </h3>
              <p className="paragraph">
                Collaboration is at the heart of what I do. I want to get to
                know you, understand your vision, and collaborate to create
                images that reflect your personality and the essence of the
                moment. With your vision and my creative talents and
                photographic expertise, we are destined to exceed your
                expectations. Whether it's the impact of a headshot, the joy of
                a family gathering, or the professionalism of a corporate event,
                I'm here to bring your story to life.
              </p>

              <h3 className="heading-tertiary u-margin-bottom-small">
                You want to book a professional
              </h3>
              <p className="paragraph">
                We are not just technically proficiency but genuinely committed
                to creating an experience that goes beyond the click of a
                shutter. As a proud member of Professional Photographers of
                America and Texas Professional Photographers of America, we
                bring a blend of technical expertise and artistic flair to every
                project. I am committed to continuous learning, staying abreast
                of industry trends, and exploring new techniques to elevate my
                craft.
              </p>

              {/* <h3 className="heading-tertiary u-margin-bottom-small">You want the process to be easy</h3>
                        <p className="paragraph">
                            From online services to personal consultations, ... 
                        </p> */}

              <a href="#popup" className="btn-text">
                Book Session &rarr;
              </a>
            </div>
            <div className="col-1-of-2">
              <div
                className="composition"
                title="Portraits by Matzke Photography"
              >
                <img
                  srcSet={`${portrait3} 300w`}
                  sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                  alt="1"
                  className="composition__photo composition__photo--p1"
                  src={portrait3}
                  loading="lazy"
                />

                <img
                  srcSet={`${portrait2} 300w`}
                  sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                  alt="2"
                  className="composition__photo composition__photo--p2"
                  src={portrait2}
                  loading="lazy"
                />

                <img
                  srcSet={`${portrait1} 300w`}
                  sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                  alt="3"
                  className="composition__photo composition__photo--p3"
                  src={portrait1}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section-stories">
          <div className="bg-video">
            <video className="bg-video__content" autoPlay muted loop>
              <source src={backgrondVideo1} type="video/mp4" />
              <source src={backgrondVideo2} type="video/webm" />
              Your browser is not supported!
            </video>
          </div>

          <div className="u-center-text u-margin-bottom-big">
            <h2 className="heading-secondary">
              We make people genuinely happy
            </h2>
          </div>

          <div className="row">
            <div className="story">
              <figure className="story__shape">
                <img
                  src={story1}
                  alt="Customer Review"
                  className="story__img"
                />
                <figcaption className="story__caption">
                  Tatiana Armenta{" "}
                </figcaption>
              </figure>
              <div className="story__text">
                <h3 className="heading-tertiary u-margin-bottom-small">
                  Could not wait to review Stan!
                </h3>
                <p>
                  He swooped in to save the day very last minute when our
                  photographer could not make it for a family and friends Santa
                  session. Stan was on it! Professional and made sure everyone
                  was comfortable and photographed in the best light. He stayed
                  extra time just to make sure everyone got ample time with
                  Santa. He was pleasant and brought props which was awesome. I
                  just loved that he was there to make the day perfect, even
                  though he didn't have to. Would recommend any day! Great
                  equipment and great quality shots. Awesome attitude.
                </p>
              </div>
            </div>
          </div>

          {/* <div className="row">
                    <div className="story">
                        <figure className="story__shape">
                            <img src="img/nat-9.jpg" alt="Person on a tour" className="story__img" />
                            <figcaption className="story__caption">Jack Wilson</figcaption>
                        </figure>
                        <div className="story__text">
                            <h3 className="heading-tertiary u-margin-bottom-small">WOW! My life is completely different now</h3>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, ipsum sapiente aspernatur libero repellat quis consequatur
                                ducimus quam nisi exercitationem omnis earum qui. Aperiam, ipsum sapiente aspernatur libero
                                repellat quis consequatur ducimus quam nisi exercitationem omnis earum qui.
                            </p>
                        </div>
                    </div>
                </div> */}

          {/* <div className="u-center-text u-margin-top-huge">
                    <a href="#" className="btn-text">Read all stories &rarr;</a>
                </div> */}
        </section>

        <section className="section-process">
          <div className="row">
            <div className="process__text">
              <h2 className="heading-secondary u-margin-bottom-small">
                It’s an easy process.
              </h2>
              <h3 class="heading-tertiary u-margin-bottom-small">
                Book a session
              </h3>
              <p className="paragraph">
                We strive to make your experience seamless and enjoyable. Our
                process is designed to provide you with photographs tailored to
                your requirements.
              </p>
              <p className="paragraph">
                The journey begins with a personalized consultation. We take the
                time to understand your goals, preferences, and requirements.
                Whether you're looking for portraits, headshots, or images of an
                event captured, we work closely with you to ensure a
                comprehensive understanding of your vision.
              </p>
              <p className="paragraph">
                Following the consultation, we present you with a detailed quote
                outlining the scope of work, timelines, and costs involved. We
                believe in transparency and open communication, and we're happy
                to address any questions or modifications you may have before
                moving forward.
              </p>

              <h3 class="heading-tertiary u-margin-bottom-small">
                Accept the online quote
              </h3>
              <p className="paragraph">
                Once the quote is accepted and the retainer is paid, your
                session date is booked and that time will be reserved in our
                calendar. We will handle things from there.
              </p>

              <h3 class="heading-tertiary u-margin-bottom-small">
                On the day of the session
              </h3>
              <p className="paragraph">
                Arrive well rested and a little bit early to ensure we have a
                relaxed start. Bring your smile and sparkling personality and we
                will be there to guide you through the session.
              </p>
            </div>
          </div>
        </section>

        <section className="section-book" id="popup">
          <div className="row">
            <div className="book">
              <div className="book__form">
                <iframe
                  name="lc_contact_form"
                  title="Booking"
                  frameBorder="0"
                  width="100%"
                  height="600"
                  src="https://MatzkePhotography.17hats.com/p#/embed/wrskdnrtswxdgkszdztgrbfppsdnzkdz"
                ></iframe>
                <script
                  type="text/javascript"
                  src="https://MatzkePhotography.17hats.com/vendor/iframeSizer.min.js"
                ></script>
              </div>
            </div>
          </div>
        </section>

        <section className="gallery" title="Photographs by Matzke Photography">
          <figure className="gallery__item gallery__item--1 ">
            <img
              src={gal1}
              loading="lazy"
              alt="Group Portrait 1"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--2">
            <img
              src={gal2}
              loading="lazy"
              alt="Santa Portrait 1"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--3">
            <img
              src={gal3}
              loading="lazy"
              alt="Group Portrait 2"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--4">
            <img
              src={gal4}
              loading="lazy"
              alt="Portrait 1"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--5">
            <img
              src={gal5}
              loading="lazy"
              alt="Event Photography"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--6">
            <img
              src={gal6}
              loading="lazy"
              alt="Event Photography"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--7">
            <img
              src={gal7}
              loading="lazy"
              alt="Event Photography"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--8">
            <img
              src={gal8}
              loading="lazy"
              alt="Portrait 2"
              className="gallery__img"
            />
          </figure>

          <figure className="gallery__item gallery__item--10">
            <img
              src={gal10}
              loading="lazy"
              alt="Event Photography"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--11">
            <img
              src={gal11}
              loading="lazy"
              alt="Portrait"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--12">
            <img
              src={gal12}
              loading="lazy"
              alt="Santa Photographs"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--13">
            <img
              src={gal13}
              loading="lazy"
              alt="Portrait"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--14">
            <img
              src={gal14}
              loading="lazy"
              alt="Photography Matzke Photography 14"
              className="gallery__img"
            />
          </figure>

          <figure className="gallery__item gallery__item--15">
            <img
              src={gal15}
              loading="lazy"
              alt="Portrait"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--16">
            <img
              src={gal16}
              loading="lazy"
              alt="Portrait"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--17">
            <img
              src={gal17}
              loading="lazy"
              alt="Portrait"
              className="gallery__img"
            />
          </figure>
          <figure className="gallery__item gallery__item--18">
            <img
              src={gal18}
              loading="lazy"
              alt="Portrait"
              className="gallery__img"
            />
          </figure>
        </section>
      </main>
    </div>
  );
}

export default Home;
