import React from "react";
import { NavLink, Link } from "react-router-dom";
import "../../assets/css/main.css"

function Header() {
  return (
    <div>
      <div className="navigation">
        <input title="Navigation"
          type="checkbox"
          className="navigation__checkbox"
          id="navi-toggle"
        />

        {/* <label htmlFor="navi-toggle" className="navigation__button">
          <span className="navigation__icon"></span>
        </label> */}

        {/* <div className="navigation__background"></div> */}

        <nav className="navigation__nav">
          <ul className="navigation__list">
            <li className="navigation__item">
              <a href="#" className="navigation__link">
                <span>01</span>About Natous
              </a>
            </li>
            <li className="navigation__item">
              <a href="#" className="navigation__link">
                <span>02</span>Your benfits
              </a>
            </li>
            <li className="navigation__item">
              <a href="#" className="navigation__link">
                <span>03</span>Popular tours
              </a>
            </li>
            <li className="navigation__item">
              <a href="#" className="navigation__link">
                <span>04</span>Stories
              </a>
            </li>
            <li className="navigation__item">
              <a href="#" className="navigation__link">
                <span>05</span>Book now
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <header className="header">
            <div className="header__logo-box">
                {/* <img src="../../images/logo-white.png" alt="Logo" className="header__logo" /> */}
            </div>

            <div className="header__text-box">
                <h1 title="Professional Photography Services in the Houston area" className="heading-primary">
                    <span className="heading-primary--main">Matzke Photography</span>
                    <span className="heading-primary--sub">Capturing Memorable Moments</span>
                </h1>

                <a href="#section-tours" className="btn btn--green btn--animated">Discover Services</a><br></br>
                <a href="https://mailchi.mp/e8f30ef9813a/weve-got-a-sweetheart-of-a-deal-for-you-and-your-valentines" className="btn btn--green btn--animated special-offer">Valentine's Mini Sessions</a>
            </div>
        </header>
    </div>
  );
}

export default Header;
